import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />}/>
          <Route path='/about-me' />
          <Route path='/projects' />
          <Route path='/experience' />
          <Route path='/contact' />
        </Routes>
      </Router>
    </>
  );
}

export default App;