import React from 'react';
import '../App.css';
import './HeroSection.css';
import { ReactTyped } from "react-typed";

function HeroSection() {
  return (
    <div className='hero-container'>
      <ReactTyped className='hi-text' strings={["Hi, I'm Shriya!"]} typeSpeed={100} />
      <p>More coming soon :)</p>
    </div>
  );
}

export default HeroSection;